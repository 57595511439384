import React from "react";
// External Libraries
// import { useStore } from "react-context-hook";
// Custom components
import ThankYouDetails from "./constants/ThankYouDetails";

const ThankYouModal = (props) => {
  const {} = props;

  let data = ThankYouDetails["ID"];

  return (
    <div
      className="modal pr-0 fade"
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col flex-column justify-content-center">
                  <div className="col text-center">
                    <h5 className="title" id="exampleModalLongTitle">
                      {data.title}
                    </h5>
                    <p className="smallerSubtitle" style={{ color: "#707070" }}>
                      {data.body}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouModal;
