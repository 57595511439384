/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import React, { Fragment } from "react";
import Slider from "react-slick";

const data = [
  {
    url:
      "https://techcrunch.com/2016/03/20/servishero-a-mobile-app-for-finding-local-services-in-southeast-asia-lands-2-7m/",
    image: require("../../images/featured-companies/TC.png"),
    name: "TechCrunch",
  },
  {
    url:
      "https://www.techinasia.com/servishero-raises-funding-launches-in-thailand",
    image: require("../../images/featured-companies/TechInAsia.png"),
    name: "TechInAsia",
  },
  {
    url:
      "https://edition.cnn.com/videos/world/2017/03/08/malaysia-app-servishero-road-to-asean-cnn-orig.cnn",
    image: require("../../images/featured-companies/CNN.png"),
    name: "CNN",
  },
  {
    url: "https://www.youtube.com/watch?v=EkOcEwSrOYc&feature=youtu.be",
    image: require("../../images/featured-companies/Bloomberg.png"),
    name: "Bloomberg",
  },
  {
    url:
      "https://www.thestar.com.my/metro/focus/2015/10/14/managing-tasks-the-smart-way-apps-to-improve-work-productivity-and-efficiency/",
    image: require("../../images/featured-companies/Star.png"),
    name: "Star",
  },
  {
    url:
      "https://vulcanpost.com/268931/servishero-myteksi-local-service-industry/",
    image: require("../../images/featured-companies/Vulcan.png"),
    name: "Vulcan",
  },
  {
    url:
      "https://e27.co/up-up-and-away-servishero-lands-us2-7m-led-by-golden-gate-enters-thailand-20160320/",
    image: require("../../images/featured-companies/e27.png"),
    name: "e27",
  },
  {
    url:
      "http://inc-asean.com/startup/southeast-asias-start-ups-need-to-pluck-the-low-lying-fruit/",
    image: require("../../images/featured-companies/Inc.png"),
    name: "Inc",
  },
  {
    url:
      "https://www.forbes.com/sites/groupthink/2017/03/23/how-the-gig-economy-could-drive-growth-in-developing-countries/#25f3d3a94a49",
    image: require("../../images/featured-companies/Forbes.png"),
    name: "Forbes",
  },
  {
    url: "https://techsauce.co/news/servishero-launches-in-thailand/",
    image: require("../../images/featured-companies/TechSauce.png"),
    name: "TechSauce",
  },
  {
    url: "https://youtu.be/Rtb5hKiMEns",
    image: require("../../images/featured-companies/cna.png"),
    name: "CNA",
  },
  {
    url: "#",
    image: require("../../images/featured-companies/channel8.png"),
    name: "channel8",
  },
];

const FeaturedInSlider = () => {
  const ImageLink = (props) => {
    const { url, image, name } = props;
    return (
      <div>
        <a href={url} target="_blank">
          <img src={image} style={{ width: "auto", maxHeight: 120 }} alt={name} />
        </a>
      </div>
    );
  };

  const settings = {
    className: "featured-companies",
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <Slider {...settings} style={{maxHeight: 120}}>
      {data &&
        data.map((item) => {
          return (
            <ImageLink
              key={item.name}
              url={item.url}
              image={item.image}
              name={item.name}
            />
          );
        })}
    </Slider>
  );
};

export default FeaturedInSlider;
