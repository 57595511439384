/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import { commercial as commData, vehicle as vehicleData } from "./constants";

const DisinfectionSlider = (props) => {
  const { commercial = false, vehicle = false } = props;

  const data = commercial ? commData : vehicle ? vehicleData : null;

  return (
    <div className="disinfection-slider">
      <Carousel
        centered
        infinite
        dots
        slidesPerPage={2}
        autoPlay={2500}
        animationSpeed={1000}
        stopAutoPlayOnHover
        breakpoints={{
          544: {
            slidesPerPage: 1,
          },
          900: {
            slidesPerPage: 2,
          },
        }}
      >
        {data.map((item) => (
          <div key={item.caption && item.caption} className="mx-2">
            <img src={item.image} alt={item.caption && item.caption} />
            {!!item.caption && (
              <p
                className="smallerSubtitle text-center px-3 py-2 align-middle"
                style={{
                  backgroundColor: "#ffcc09",
                  height: "auto",
                  width: "auto",
                  whiteSpace: "pre-line",
                  minHeight: 65,
                }}
              >
                {item.caption && item.caption}
              </p>
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default DisinfectionSlider;
