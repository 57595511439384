import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
// Custom components
import QuoteRequestForm from "./components/QuoteRequestForm";

const GetQuoteModal = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className="modal pr-0 fade"
      id="getQuoteModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="getQuoteModalTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <h2 className="modal-title">{t("QUOTEREQUESTFORM.formTitle")}</h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <QuoteRequestForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetQuoteModal;
