import React, { Fragment, useState } from "react";
// External libraries
import moment from "moment";
// import ReactGA from "react-ga";
import $ from "jquery";
import { useTranslation } from "gatsby-plugin-react-i18next";

// ReactGA.initialize("AW-732718445");

export default () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [contactNumberTH, setContactNumber] = useState(null);
  const [problem, setProblem] = useState(null);
  const [location, setLocation] = useState(null);
  const [leads, setLeads] = useState(null);

  function resetForm() {
    setEmail(null);
    setName(null);
    setContactNumber(null);
    setProblem(null);
    setLocation(null);
    setLeads(null);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const now = new Date();

    const formValues = {
      timestamp: moment(now).format("DD-MM-YYYY, h:mm a"),
      name,
      "contact-number": contactNumberTH,
      email,
      problem,
      location,
      leads,
    };

    // const callback = () => {
    //   console.log("event successful");
    //   $("#getQuoteModal").modal("hide");
    //   $("#thankYouModal").modal("show");
    //   window.scrollTo(0, 0);
    // };

    $.ajax({
      url:
        "https://script.google.com/macros/s/AKfycbzEa4A2FzXfujIkBtHYgDy4mRTcjoIRRAKNy2O6OWvmdYwF85k/exec",
      method: "GET",
      dataType: "json",
      data: formValues,
      success() {
        console.log("submit successful");
        resetForm();
        document.getElementById("interest-form").reset();

        // ReactGA.ga("event", "conversion", {
        //   send_to: "AW-732718445/O4o-CKuAwssBEO3Ksd0C",
        //   event_callback: callback(),
        // });
        $("#getQuoteModal").modal("hide");
        $("#thankYouModal").modal("show");
        window.scrollTo(0, 0);
      },
    });
  }

  return (
    <Fragment>
      <form id="interest-form" className="" onSubmit={handleSubmit}>
        <input type="hidden" id="timestamp" name="timestamp" value="" />
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder={t("CALLBACKFORM.name")}
            required
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            className="form-control"
            id="contact-number"
            name="contact-number"
            placeholder={t("CALLBACKFORM.contactNumber")}
            required
            onChange={(e) => setContactNumber(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <select
            required
            className="form-control"
            onChange={(e) => setLeads(e.currentTarget.value)}
          >
            <option value="">{t("QUOTEREQUESTFORM.leadsSelectionTitle")}</option>
            <option value="professional fogging">
            {t("QUOTEREQUESTFORM.leadsOption1")}
            </option>
            <option value="advanced coating">
            {t("QUOTEREQUESTFORM.leadsOption2")}
            </option>
          </select>
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            rows="3"
            id="problem"
            name="problem"
            placeholder={t("CALLBACKFORM.problem")}
            onChange={(e) => setProblem(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            rows="3"
            id="location"
            name="location"
            placeholder={t("CALLBACKFORM.location")}
            onChange={(e) => setLocation(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder={t("CALLBACKFORM.email")}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
        <div className="text-center">
          <button
            type="submit"
            id="submit-form"
            className="CTA btn btn-primary px-5 mx-auto"
          >
            {t("CALLBACKFORM.submitBtn")}
          </button>
        </div>
      </form>
    </Fragment>
  );
};
