export default [
  {
    image: require("../../../images/slider/vehicle/disinfection-carbooth.png"),
    caption: "",
  },
  {
    image: require("../../../images/slider/vehicle/disinfection-car-outer.png"),
    caption: "",
  },
  {
    image: require("../../../images/slider/vehicle/disinfection-driverseat.png"),
    caption: "",
  },
  {
    image: require("../../../images/slider/vehicle/disinfection-engine.png"),
    caption: "",
  },
  {
    image: require("../../../images/slider/vehicle/disinfection-frontseat.png"),
    caption: "",
  },
  {
    image: require("../../../images/slider/vehicle/disinfection-lorry.png"),
    caption: "",
  },
];
